import React from 'react'
import styled from 'styled-components'
import media from '@gtg/utils/media-queries'

import formatMoney from '../utils/formatMoney'

const Right = styled.td`
  text-align: right;
`

const RightH = styled.th`
  text-align: right;
`

const Center = styled.td`
  text-align: center;
`

const Table = styled.table`
  margin-bottom: 0;
`

const MobilePrice = styled.div`
  display: none;
  ${media.sm`
    display: block;
  `}
`

const DesktopPrice = styled.div`
  display: block;
  ${media.sm`
    display: none;
  `}
`

const PricingTable = props => {
  const { qm, qmPrice, days, dayFactor, km, kmPrice, setUpFee, price } =
    props.pricingData

  return (
    <>
      <MobilePrice>
        <Table>
          <thead>
            <tr>
              <th>Posten</th>
              <RightH>Summe</RightH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Zelte</td>
              <Right>{formatMoney(qm * qmPrice * dayFactor)}</Right>
            </tr>
            <tr>
              <td>Anfahrt</td>
              <Right>{formatMoney(km * kmPrice * 4)}</Right>
            </tr>
            <tr>
              <td>Aufbau</td>
              <Right>{formatMoney(setUpFee)}</Right>
            </tr>
            <tr>
              <td>
                <b>Netto</b>
              </td>
              <Right>
                <b>{formatMoney(price)}</b>
              </Right>
            </tr>
          </tbody>
        </Table>
      </MobilePrice>
      <DesktopPrice>
        <Table>
          <thead>
            <tr>
              <th>Posten</th>
              <th>Anzahl</th>
              <th>€ / Einheit</th>
              <th>Tage</th>
              <RightH>Summe</RightH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Zelte</td>
              <td>{qm} qm</td>
              <Right>-</Right>
              <Center>{days}</Center>
              <Right>{formatMoney(qm * qmPrice * dayFactor)}</Right>
            </tr>
            <tr>
              <td>Anfahrt</td>
              <td>{km} km (x4)</td>
              <Right>{formatMoney(kmPrice)}</Right>
              <Center>-</Center>
              <Right>{formatMoney(km * kmPrice * 4)}</Right>
            </tr>
            <tr>
              <td>Aufbau</td>
              <td>pauschal</td>
              <Right>{formatMoney(setUpFee)}</Right>
              <Center>-</Center>
              <Right>{formatMoney(setUpFee)}</Right>
            </tr>
            <tr>
              <td>
                <b>Summe</b> (exkl. MwSt.)
              </td>
              <td></td>
              <td></td>
              <Center></Center>
              <Right>
                <b>{formatMoney(price)}</b>
              </Right>
            </tr>
          </tbody>
          {console.log('qmPrice: ' + qmPrice)}
          {console.log('dayFactor: ' + dayFactor)}
        </Table>
      </DesktopPrice>
    </>
  )
}

export default PricingTable
