export const tents = [
  { width: 6, length: 8, color: 'beige', checked: false },
  { width: 7.5, length: 10, color: 'weiß', checked: false },
  { width: 7.5, length: 10, color: 'beige', checked: false },
  { width: 7.5, length: 10, color: 'rot', checked: false },
  { width: 8, length: 12, color: 'beige', checked: false },
  { width: 8, length: 12, color: 'beige', checked: false },
  { width: 8, length: 12, color: 'anthrazit', checked: false },
  { width: 10, length: 12, color: 'weiß', checked: false },
  { width: 10, length: 14, color: 'beige', checked: false },
  { width: 10, length: 15, color: 'beige', checked: false },
  { width: 10, length: 15, color: 'beige', checked: false },
]
