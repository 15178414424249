const calculatePrice = (ground, startDate, endDate, distance, qm) => {
  const setUpFee = 200
  const km = Math.floor(distance / 1000)
  const kmPrice = 1.00

  let groundPrice
  switch (ground) {
    case 'Wiese':
      groundPrice = 0
      break
    case 'Asphalt / Beton':
      groundPrice = 2
      break
    case 'Sand':
      groundPrice = 0
      break
    case 'Erde':
      groundPrice = 0
      break
  }

  let qmPrice
  qmPrice = 13.5 + groundPrice
  if (qm > 100) {
    qmPrice = 12.5 + groundPrice
  }
  if (qm > 200) {
    qmPrice = 12 + groundPrice
  }

  const days = Math.max(
    1,
    (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
  )

  const dayFactor = days < 4 ? 1 : (days + 3) / 6

  const price = dayFactor * qm * qmPrice + setUpFee + km * kmPrice * 4

  let pricingData = {
    qm: qm,
    qmPrice: qmPrice,
    days: days,
    dayFactor: dayFactor,
    km: km,
    kmPrice: kmPrice,
    setUpFee: setUpFee,
    price: price,
  }

  return pricingData
}

export default calculatePrice
