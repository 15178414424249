import React from 'react'

import Seo from '@gtg/components/seo'
import GlobalWrapper from '../components/globalWrapper'
import Nav from '@gtg/components/nav'
import Footer from '@gtg/components/footer'
import Hero from '../components/hero'
import Calculator from '../components/calculator/'
import Options from '../components/options'

const Index = () => {
  return (
    <GlobalWrapper>
      <Seo title="Stretchzelte mieten" />
      <Nav caps wMax="xl" />
      <Hero />
      <Calculator />
      <Options />
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default Index
