import React from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { color } from '@gtg/styles/theme'
import styled from 'styled-components'
import GoogleLogoSrc from '../../img/powered_by_google_on_white.png'

const windowGlobal = typeof window !== 'undefined' && window

const Div = styled.div`
  .location-search-input {
    margin: 0;
  }
  box-shadow: ${props => (props.isValid ? 'none' : '0 0 0 2px #ff8484')};
  border-radius: 8px;
  margin-bottom: 0;
  div {
    margin: 0 !important;
  }
`

const DropDownContainer = styled.div`
  background: white;
  margin: 0;
  position: absolute;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 52px;
  background-size: auto 18px;
  background-position: 50% 93%;
  background-repeat: no-repeat;
  background-image: url(${GoogleLogoSrc});
  .suggestion-item,
  .suggestion-item--active {
    margin-bottom: 0;
    padding: 0.5rem;
  }
`

const Loading = styled.div`
  padding: 0.5rem;
`

let searchOptions
let setDistance
let setAddress

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { address: '' }
    setDistance = props.setDistance
    setAddress = props.setAddress
  }

  componentDidMount() {
    this.initSearchOptions()
  }

  initSearchOptions() {
    searchOptions = {
      location: new windowGlobal.google.maps.LatLng(48.250202, 11.652122),
      radius: 1000,
    }
  }

  handleChange = address => {
    this.setState({ address })
  }

  handleSelect = address => {
    this.setState({ address })
    setAddress(address)
    this.getDistance(address)
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .catch(error => console.error('Error', error))
  }

  getDistance = address => {
    var origin = 'Freisinger Landstraße 1, 85748 Garching, Deutschland'
    var destination = address
    var service = new windowGlobal.google.maps.DistanceMatrixService()
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
        unitSystem: windowGlobal.google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      response_data
    )

    function response_data(responseDis, status) {
      if (
        status !== windowGlobal.google.maps.DistanceMatrixStatus.OK ||
        status !== 'OK'
      ) {
        console.log('Error:', status)
        setDistance('error')
      } else {
        if (responseDis.rows[0].elements[0].distance) {
          setDistance(responseDis.rows[0].elements[0].distance.value)
        }
      }
    }
  }

  render() {
    return (
      <Div isValid={this.props.isValid}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Ort suchen ...',
                  className: 'location-search-input',
                })}
              />
              <DropDownContainer className="autocomplete-dropdown-container">
                {loading && <Loading>Lädt...</Loading>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  const style = suggestion.active
                    ? { backgroundColor: color.grey150, cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' }
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={suggestion.placeId}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </DropDownContainer>
            </div>
          )}
        </PlacesAutocomplete>
      </Div>
    )
  }
}

export default LocationSearchInput
