import React from 'react'
import styled, { keyframes } from 'styled-components'
import { color } from '@gtg/styles/theme'

const OuterSpinnerCircle = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid #d9ebff;
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  right: 0;
  bottom: 0;
  display: inline-block;
`
const Rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`

const InnerSpinnerCircle = styled.div`
  width: 100%;
  height: 100%;
  border-right: 4px solid ${color.blue500};
  border-left: 4px solid transparent;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  box-sizing: content-box;
  position: relative;
  top: -4px;
  left: -4px;
  right: 0;
  bottom: 0;
  animation: ${Rotate} 1.5s linear infinite;
`

const SubmitSpinner = () => (
  <OuterSpinnerCircle>
    <InnerSpinnerCircle />
  </OuterSpinnerCircle>
)

export default SubmitSpinner
