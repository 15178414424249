import React, { useState } from 'react'

import styled from 'styled-components'
import media from '@gtg/utils/media-queries'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { color, fontSize } from '@gtg/styles/theme'

import GoogleLogoSrc from '../../img/powered_by_google_on_white.png'

import { useModal } from '@gtg/components/modal/useModal'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
registerLocale('de', de)
setDefaultLocale('de')

import Container from '@gtg/components/container'
import Box from '@gtg/components/box'
import LocationSearchInput from './locationSearchInput'
import PricingTable from './PricingTable'
import Spinner from '../spinner'

import { tents } from '../../data/tents'
import calculatePrice from '../helpers/calculatePrice'

const windowGlobal = typeof window !== 'undefined' && window

const StyledSection = styled.section`
  background: #ecf2f8;
  color: #243a4a;
`

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 3rem 1fr 1fr;
  grid-template-rows: auto 2.5rem auto 2.5rem auto 2.5rem auto auto;
  grid-column-gap: 1.5rem;
  margin: 3rem 0;
  font-size: ${fontSize.f3};
  input,
  select,
  input[type='datetime-local'],
  input:not([type]) {
    box-shadow: none;
    :focus {
      box-shadow: 0 0 0 2px ${color.lightBlue500};
    }
  }
  ${media.lg`
    grid-template-columns: 1fr 1rem 1fr 1fr;
  `}
  ${media.md`
    grid-template-columns: auto auto;
    display: block;
    margin: 2rem 0;
  `}
  ${media.sm`
    margin: 2rem 0 1rem 0;
  `}
`

const H1 = styled.h1``

const Error = styled.div`
  background: #ffefef;
  box-shadow: 0 0 0 2px #ff8484;
  border-radius: 8px;
  min-height: 3.5rem;
  padding: 0.75rem 0.75rem 0.6rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  ${media.sm`
    margin-top: 0;
  `}
`

const Tents = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  ${media.md`
    margin-bottom: 1rem;
  `}
`

const TentSelectErrorWrapper = styled.div`
  grid-row: 1 / 2;
  grid-column: 3 / 5;
  ${media.md`
    margin-bottom: 1rem;
  `}
`

const TentSelectWrapper = styled.div`
  overflow: hidden;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  box-shadow: ${props => (props.isValid ? 'none' : '0 0 0 2px #ff8484')};
  ${media.lg`
    padding: 1rem;
  `}
  ${media.sm`
    padding: 0;
  `}
`

const TentSelect = styled.table`
  margin-bottom: 0;
  label {
    margin: 0;
  }
  input[type='checkbox'],
  input[type='radio'] {
    margin: 0;
    transform: 0;
  }
  th,
  td {
    white-space: nowrap;
  }
  ${media.lg`
    padding: 0.75rem 1.25rem;
  `}
  ${media.xs`
    padding: 0.25rem 0.5rem;
  `}
`

const Ground = styled.div`
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  ${media.md`
    margin-bottom: 1rem;
  `}
`

const GroundSelect = styled.div`
  grid-row: 3 / 4;
  grid-column: 3 / 5;
  ${media.md`
    margin-bottom: 1rem;
  `}
`

const Period = styled.div`
  grid-row: 5 / 6;
  grid-column: 1 / 2;
  ${media.md`
    margin-bottom: 1rem;
  `}
`

const StartPeriod = styled.div`
  grid-row: 5 / 6;
  grid-column: 3 / 4;
  width: 100%;
  button {
    border-radius: 0;
    :hover {
      background: transparent;
    }
  }
  .react-datepicker-wrapper {
    display: block;
  }
  input {
    margin-bottom: 0;
    box-shadow: ${props => (props.isValid ? 'none' : '0 0 0 2px #ff8484')};
  }
  ${media.md`
    margin-bottom: 1rem;
  `}
`

const EndPeriod = styled(StartPeriod)`
  grid-column: 4 / 5;
`

const Location = styled.div`
  grid-row: 7 / 8;
  grid-column: 1 / 2;
  ${media.md`
    margin-bottom: 1rem;
  `}
`

const LocationInput = styled.div`
  grid-row: 7 / 8;
  grid-column: 3 / 5;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Button = styled.button`
  padding: 1.25rem 9rem;
  background: #006e9a;
  font-weight: 600;
  letter-spacing: 0.3px;
  ${media.xl`
    padding: 1rem 6rem;
  `}
  ${media.sm`
    width: 100%;
    padding: 1rem;
  `}
  :hover {
    background: #009cda;
  }
`

const Spacer = styled.div`
  display: none;
  ${media.md`
    display: block;
    height: 1rem;
    margin-bottom: 1rem;
  `}
  ${media.sm`
    height: 0.25rem;
  `}
`

const Hr = styled.hr`
  ${media.md`
    display: none;
  `}
`

// --- Dialog ---

const Dialog = styled.div`
  background: #ecf2f8;
`

const DialogH1 = styled.h1`
  font-family: 'Stretchzelt', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: ${fontSize.f7};
  text-align: center;
  ${media.sm`
    font-size: ${fontSize.f6};
    margin-top: 3rem;
  `}
  ${media.xs`
    font-size: ${fontSize.f4};
  `}
`

const DialogH2 = styled.h2`
  font-family: 'Stretchzelt', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: ${fontSize.f7};
  text-align: center;
  text-transform: initial;
  margin: 2rem 0;
  ${media.sm`
    font-size: ${fontSize.f6};
    margin: 1rem 0;
  `}
  ${media.xs`
    font-size: ${fontSize.f4};
  `}
`

const PriceWrapper = styled.div`
  padding: 1.5rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`

const PriceInnerWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

const DialogFormGrid = styled.form`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  input,
  select,
  textarea,
  input:not([type]),
  input[type='tel'],
  input[type='email'],
  input[type='text'] {
    box-shadow: none;
    :focus {
      box-shadow: 0 0 0 2px ${color.lightBlue500};
    }
  }
  grid-column-gap: 1rem;
  ${media.sm`
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto auto auto;
  `}
`

const Name = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
`

const Company = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  ${media.sm`
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  `}
`

const Email = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  ${media.sm`
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  `}
`

const Phone = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  ${media.sm`
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  `}
`

const Note = styled.div`
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  ${media.sm`
    grid-column: 1 / 2;
    grid-row: 5 / 6;
  `}
`

const DisclaimerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Disclaimer = styled.div`
  font-size: ${fontSize.f1};
  text-align: center;
  margin: 2rem 0;
  width: 80%;
  ${media.sm`
    margin: 1.5rem 0 1rem 0;
  `}
`

const DialogButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 1 / 3;
  grid-row: 4 / 5;
  margin-top: 1rem;
  ${media.sm`
    grid-column: 1 / 2;
    grid-row: 6 / 7;
  `}
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px;
`

const HiddenFields = styled.div`
  display: none;
`

const GoogleLogo = styled.div`
  background: url(${GoogleLogoSrc}) center right/contain no-repeat;
  width: 100%;
  height: 18px;
  margin-top: 1rem;
`

const CenterText = styled.div`
  text-align: center;
`

const Calculator = () => {
  const { open, RenderModal } = useModal()
  const [ground, setGround] = useState('Wiese')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [distance, setDistance] = useState(0)
  const [address, setAddress] = useState()
  const [qm, setQm] = useState(0)
  const [tentList, setTentList] = useState(tents)
  const [tentString, setTentString] = useState('')
  const [pricingData, setPricingData] = useState({})
  const [requestState, setRequestState] = useState('OPEN')

  const updateTentList = id => {
    const newTentList = [...tentList]
    newTentList[id].checked = !tentList[id].checked
    setTentList(newTentList)
    let sumQm = 0
    newTentList.forEach(tent => {
      if (tent.checked) {
        sumQm = sumQm + tent.width * tent.length
      }
    })
    setQm(sumQm)
    updateTentString()
  }

  const updateTentString = () => {
    let tentString = ''
    tentList.forEach(tent => {
      if (tent.checked) {
        tentString =
          tentString +
          tent.length +
          ' x ' +
          tent.width +
          ' ' +
          tent.color +
          ' | '
      }
    })
    setTentString(tentString)
  }

  const [tentListIsValid, setTentListIsValid] = useState(true)
  const [startPeriodIsValid, setStartPeriodIsValid] = useState(true)
  const [startPeriodErrorText, setStartPeriodErrorText] = useState()
  const [endPeriodErrorText, setEndPeriodErrorText] = useState()
  const [endPeriodIsValid, setEndPeriodIsValid] = useState(true)
  const [locationIsValid, setLocationIsValid] = useState(true)

  const validateInput = () => {
    let allValid = true
    if (qm === 0) {
      setTentListIsValid(false)
      allValid = false
    } else {
      setTentListIsValid(true)
    }
    if (!startDate) {
      setStartPeriodErrorText(
        'Bitte wählen Sie einen Zeitpunkt für den Mietbeginn.'
      )
      setStartPeriodIsValid(false)
      allValid = false
    }
    if (!endDate) {
      setEndPeriodErrorText(
        'Bitte wählen Sie einen Zeitpunkt für das Mietende.'
      )
      setEndPeriodIsValid(false)
      allValid = false
    }
    let now = new Date()
    if (startDate) {
      if (startDate.getTime() < now.getTime()) {
        setStartPeriodIsValid(false)
        setStartPeriodErrorText('Der Mietbeginn muss in der Zukunft liegen.')
        allValid = false
      } else {
        setStartPeriodIsValid(true)
      }
    }
    if (endDate) {
      if (endDate.getTime() < now.getTime()) {
        setEndPeriodIsValid(false)
        setEndPeriodErrorText('Das Mietende muss in der Zukunft liegen.')
        allValid = false
      }
    }
    if (startDate && endDate) {
      if (endDate.getTime() <= startDate.getTime()) {
        setEndPeriodIsValid(false)
        setEndPeriodErrorText('Der Mietbeginn muss vor dem Mietende liegen.')
        allValid = false
      }
    }
    if (endDate) {
      if (
        endDate.getTime() >= now.getTime() &&
        endDate.getTime() > startDate.getTime()
      ) {
        setEndPeriodIsValid(true)
      }
    }
    if (distance === 'error' || distance === '' || distance === 0) {
      setLocationIsValid(false)
      allValid = false
    } else {
      setLocationIsValid(true)
    }
    if (allValid === true) {
      setPricingData(calculatePrice(ground, startDate, endDate, distance, qm))
      open()
    } else {
      scrollTo('#calculator')
    }
  }

  const resetRequestState = () => {
    setRequestState('OPEN')
  }

  const submitForm = ev => {
    ev.preventDefault()
    if (requestState === 'OPEN') {
      setRequestState('PENDING')
      const form = ev.target
      const data = new windowGlobal.FormData(form)
      const xhr = new windowGlobal.XMLHttpRequest()
      xhr.open('POST', 'https://formspree.io/xaylzngl')
      xhr.setRequestHeader('Accept', 'application/json')
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== windowGlobal.XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          form.reset()
          setRequestState('SUCCESS')
        } else {
          setRequestState('ERROR')
        }
      }
      xhr.send(data)
    }
  }

  return (
    <StyledSection id="calculator">
      <RenderModal background="#ecf2f8" onClose={resetRequestState}>
        {requestState === 'OPEN' && (
          <>
            <DialogH1>Errechneter Mietpreis</DialogH1>
            <PriceWrapper>
              <PriceInnerWrapper>
                <PricingTable pricingData={pricingData} />
              </PriceInnerWrapper>
            </PriceWrapper>
            <DisclaimerWrapper>
              <Disclaimer>
                Der angezeigte Preis dient einer groben Einschätzung und stellt
                noch kein rechtlich bindendes Angebot dar. - Fordern Sie jetzt
                Ihr individuelles Angebot an.
              </Disclaimer>
            </DisclaimerWrapper>
            <DialogH2>Jetzt Angebot anfordern</DialogH2>
            <DialogFormGrid onSubmit={submitForm}>
              <HiddenFields>
                <input
                  type="text"
                  id="tents"
                  name="Zelte"
                  value={tentString}
                  readOnly
                ></input>
                <input
                  type="text"
                  id="ground"
                  name="Untergrund"
                  value={ground}
                  readOnly
                ></input>
                <input
                  type="text"
                  id="startDate"
                  name="Mietbeginn"
                  value={startDate}
                  readOnly
                ></input>
                <input
                  type="text"
                  id="endDate"
                  name="Mietende"
                  value={endDate}
                  readOnly
                ></input>
                <input
                  type="text"
                  id="location"
                  name="Ort"
                  value={address}
                  readOnly
                ></input>
                <input
                  type="text"
                  id="price"
                  name="Preis"
                  value={Math.floor(pricingData.price).toString() + ',00'}
                  readOnly
                ></input>
              </HiddenFields>
              <Name>
                <label aria-label="Name" htmlFor="name">
                  Name
                </label>
                <input type="text" id="name" name="Name" required></input>
              </Name>
              <Company>
                <label aria-label="Firma" htmlFor="company">
                  Firma (optional)
                </label>
                <input type="text" id="company" name="Firma"></input>
              </Company>
              <Email>
                <label aria-label="E-Mail" htmlFor="email">
                  E-Mail
                </label>
                <input type="email" id="email" name="E-Mail" required></input>
              </Email>
              <Phone>
                <label aria-label="Telefon" htmlFor="phone">
                  Telefon-Nr. (optional)
                </label>
                <input type="tel" id="phone" name="Telefon"></input>
              </Phone>
              <Note>
                <label aria-label="Weitere Details" htmlFor="message">
                  Weitere Details und Wünsche (optional)
                </label>
                <textarea id="message" name="Nachricht"></textarea>
              </Note>
              <DialogButtonWrapper>
                <Button
                  onClick={() => {
                    typeof gtag !== 'undefined' &&
                      windowGlobal.gtag('event', 'click_request_offer', {})
                  }}
                >
                  Jetzt anfordern
                </Button>
              </DialogButtonWrapper>
            </DialogFormGrid>
          </>
        )}
        {requestState === 'PENDING' && (
          <>
            <DialogH1>Wird gesendet...</DialogH1>
            <Center>
              <Spinner />
            </Center>
          </>
        )}
        {requestState === 'SUCCESS' && (
          <>
            <DialogH1>Vielen Dank für Ihre Anfrage!</DialogH1>
            <CenterText>
              <p>Sie erhalten in Kürze ein Angebot von uns.</p>
            </CenterText>
          </>
        )}
        {requestState === 'ERROR' && (
          <>
            <DialogH1>Fehler</DialogH1>
            <p>Leider ist ein Fehler aufgetreten.</p>
            <p>
              Bitte senden Sie eine E-Mail an{' '}
              <a mailto="info@stretchzeltbayern.de">
                info@stretchzeltbayern.de
              </a>
            </p>
          </>
        )}
      </RenderModal>
      <Container wMax="lg">
        <Box pxMax="md" pyMax="lg">
          <H1>Mietpreis berechnen</H1>
          <Hr />
          <Form>
            <Tents>
              <h2>Zelte</h2>
              Stehend: ca 0,5 qm pro Person
              <br />
              Sitzend in Reihen: ca 1 qm pro Person
              <br />
              Sitzend an Tischen: ca 2 qm pro Person
            </Tents>
            <TentSelectErrorWrapper>
              <TentSelectWrapper isValid={tentListIsValid}>
                <TentSelect>
                  <thead>
                    <tr>
                      <th aria-label="Auswahl Checkboxen"></th>
                      <th>Größe</th>
                      <th>qm</th>
                      <th>Farbe</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tentList.map((tent, id) => (
                      <tr key={'tent' + id}>
                        <td>
                          <input
                            id={'tent' + id}
                            type="checkbox"
                            aria-label="Zelt wählen"
                            checked={tentList[id].checked}
                            onChange={() => updateTentList(id)}
                          />
                        </td>
                        <td>
                          <label
                            aria-label={'Zelt ' + id + ' auswählen'}
                            htmlFor={'tent' + id}
                            className="label-inline"
                          >
                            {tent.width} x {tent.length}
                          </label>
                        </td>
                        <td>{tent.width * tent.length}</td>
                        <td>{tent.color}</td>
                      </tr>
                    ))}
                  </tbody>
                </TentSelect>
              </TentSelectWrapper>
              {!tentListIsValid && (
                <Error>Bitte wählen Sie mindestens ein Zelt aus.</Error>
              )}
            </TentSelectErrorWrapper>
            <Spacer />
            <Ground>
              <h2>Bodenbeschaffenheit</h2>
              Auf welchem Untergrund werden die Zelte stehen?
            </Ground>
            <GroundSelect>
              <label aria-label="Bodenbeschaffenheit" htmlFor="ground-select">
                Boden
              </label>
              <select
                value={ground}
                onChange={e => setGround(e.target.value)}
                id="ground-select"
              >
                <option>Wiese</option>
                <option>Asphalt / Beton</option>
                <option>Sand</option>
                <option>Erde</option>
              </select>
            </GroundSelect>
            <Spacer />
            <Period>
              <h2>Zeitraum</h2>
              Von wann bis wann benötigen Sie die Zelte?
            </Period>
            <StartPeriod isValid={startPeriodIsValid}>
              <label aria-label="Aufbaudatum" htmlFor="from-date">
                Aufbau
              </label>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={startDate}
                onChange={date => setStartDate(date)}
                id="from-date"
              />
              {!startPeriodIsValid && <Error>{startPeriodErrorText}</Error>}
            </StartPeriod>
            <EndPeriod isValid={endPeriodIsValid}>
              <label aria-label="Abbaudatum" htmlFor="to-date">
                Abbau
              </label>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={endDate}
                onChange={date => setEndDate(date)}
                id="to-date"
              />
              {!endPeriodIsValid && <Error>{endPeriodErrorText}</Error>}
            </EndPeriod>
            <Spacer />
            <Location>
              <h2>Veranstaltungsort</h2>
              Wo sollen die Zelte aufgebaut werden?
            </Location>
            <LocationInput>
              <label aria-label="Ort">Ort</label>
              <LocationSearchInput
                setDistance={setDistance}
                setAddress={setAddress}
                isValid={locationIsValid}
              />
              {!locationIsValid && (
                <Error>Bitte geben Sie den gewünschten Mietort ein.</Error>
              )}
              <GoogleLogo />
            </LocationInput>
          </Form>
          <Hr />
          <ButtonWrapper>
            <Button onClick={() => validateInput()}>Preis berechnen</Button>
          </ButtonWrapper>
        </Box>
      </Container>
    </StyledSection>
  )
}

export default Calculator
