import React from 'react'
import ReactDependentScript from 'react-dependent-script'
import AutoCompleteInput from './autoCompleteInput'

const LocationSearchInput = props => (
  <ReactDependentScript
    scripts={[
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAlrb5HBHnFJ3C9-IjDEu7M5aYDDKawT5U&libraries=places',
    ]}
  >
    <AutoCompleteInput
      setDistance={props.setDistance}
      setAddress={props.setAddress}
      isValid={props.isValid}
    />
  </ReactDependentScript>
)

export default LocationSearchInput
