import React from 'react'
import styled from 'styled-components'
import media from '@gtg/utils/media-queries'
import Box from '@gtg/components/box'

import Container from '@gtg/components/container'

import Stretchzelt2Src from '../img/stretchzelt2.jpg'

const StyledSection = styled.section`
  max-width: 100vw;
  overflow: hidden;
`

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 4fr 5fr;
  grid-row-gap: 3rem;
  grid-column-gap: 3rem;
  margin: 4rem 0 0 0;
  ${media.lg`
    grid-row-gap: 1rem;
    margin: 3rem 0 0 0;
  `}
  ${media.md`
    display: block;
  `}
`

const LeftContainer = styled.div`
  grid-row: 1 / 2;
`

const RightContainer = styled.div`
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  ${media.md`
    margin-top: 3rem;
  `}
`

const RightBackgroundContainer = styled.div`
  position: absolute;
  grid-row: 1 / 2;
  background: linear-gradient(
    97deg,
    rgba(36, 58, 74, 0) 0%,
    rgba(36, 58, 74, 0) 7%,
    rgba(36, 58, 74, 1) 7.1%
  );
  width: 100vw;
  height: 100%;
  margin-left: -4vw;
  ${media.md`
    background: #243a4a;
    margin: 0;
    width: 100vw;
    margin-left: -3rem;
  `}
  ${media.sm`
    margin-left: -2rem;
  `}
  ${media.xs`
    margin-left: -1rem;
  `}
`

const RightTextContainer = styled.div`
  grid-row: 1 / 2;
  background: transparent;
  color: white;
  padding: 5rem 0rem 3rem 11rem;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
  ${media.lg`
    padding: 3rem 0rem 1rem 6rem;
  `}
  ${media.md`
    padding: 3rem 0 1rem 0;
  `}
`

const DividerImage = styled.section`
  height: 300px;
  width: 100%;
  background: url(${Stretchzelt2Src});
  background-size: cover;
  background-position: center center;
`

const Br = styled.br`
  ${media.md`
    display: none;
  `}
`

const Options = () => {
  return (
    <>
      <StyledSection>
        <Container wMax="lg">
          <Box pxMax="md" pyMax="lg">
            <h1>Ihre Optionen</h1>
            <GridContainer>
              <LeftContainer>
                <h2>Machen Sie es sich gemütlich</h2>
                <p>
                  Auf Wunsch bieten wir auch passende Dekoration, wie z.B.
                  Lichterketten mit an.
                </p>
                <h2>Unzählige Varianten</h2>
                <p>
                  Die Zelte lassen sich in einer Vielzahl von Höhen und Formen
                  aufbauen. Von schlicht bis extravagant. Von der kleinen Bar
                  bis zur Überdachung der gesamten Veranstaltungsfläche.
                </p>
                <p>Kontaktieren Sie uns. Wir beraten Sie gerne.</p>
              </LeftContainer>
              <RightContainer>
                <RightTextContainer>
                  <div>
                    <h2>Weitere Optionen</h2>
                    <p>Auf Wunsch kümmern wir uns auch gerne um:</p>
                    <ul>
                      <li>Biertischgarnituren</li>
                      <li>Mietmöbel</li>
                      <li>Bistrotische</li>
                      <li>Bar & Kühlschränke</li>
                      <li>Musikanlage</li>
                      <li>Beleuchtung</li>
                      <li>Beschallung</li>
                      <li>Gläser</li>
                      <li>Sonnenschirme</li>
                      <li>und vieles mehr...</li>
                    </ul>
                  </div>
                </RightTextContainer>
                <RightBackgroundContainer />
              </RightContainer>
            </GridContainer>
          </Box>
        </Container>
      </StyledSection>
      <DividerImage />
    </>
  )
}

export default Options
