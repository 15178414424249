import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import media from '@gtg/utils/media-queries'
import scrollTo from 'gatsby-plugin-smoothscroll'
import NoWrap from '@gtg/components/NoWrap'

import Container from '@gtg/components/container'
import Box from '@gtg/components/box'

import StretchzeltSrc from '../img/stretchzelt.jpg'

// const windowGlobal = typeof window !== 'undefined' && window

const StyledHero = styled.section`
  position: relative;
  background: rgb(36, 58, 74);
`

const AbsoluteContainer = styled(Container)`
  position: absolute;
  top: 0;
  width: 100%;
  ${media.md`
    position: static;
  `}
`

const GridContainer = styled.div`
  display: grid;
  height: calc(100vh - 80px);
  grid-template-rows: 1fr auto 2fr;
  grid-template-columns: 1fr 1fr;
  min-height: 800px;
  ${media.lg`
    min-height: 640px;
  `}
  ${media.md`
    grid-template-rows: auto;
    grid-template-columns: auto;
    height: auto;
    min-height: 0;
  `}
`

const TextContainer = styled.div`
  color: white;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  max-width: 700px;
  ${media.md`
    margin-top: 4rem;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  `}
  ${media.sm`
    margin-top: 3rem;
  `}
  ${media.xs`
    margin-top: 1.5rem;
  `}
`

const Img = styled.div`
  background-image: linear-gradient(
      97deg,
      rgba(36, 58, 74, 1) 0%,
      rgba(36, 58, 74, 1) 12%,
      rgba(36, 58, 74, 0) 12.05%
    ),
    url(${StretchzeltSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 45% 50%;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 4;
  @media (orientation: portrait) {
    background-position: 0% 50%;
  }
  ${media.md`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    height: 300px;
    background-image: url(${StretchzeltSrc});
    background-position: center 25%;
    margin: 3rem 0;
  `}
`

const ButtonWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-gap: 2rem;
  margin-top: 3rem;
  ${media.xl`
    margin-top: 1.5rem;
  `}
  ${media.lg`
    margin-top: 1.5rem;
    grid-gap: 1.5rem;
  `}
  ${media.sm`
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 0.5rem;
    width: 100%;
  `}
`

const Button = styled.button`
  padding: 1.25rem 4rem;
  background: #006e9a;
  font-weight: 600;
  letter-spacing: 0.3px;
  ${media.xl`
    padding: 1rem 3rem;
  `}
  ${media.sm`
    width: 100%;
  `}
  :hover {
    background: #009cda;
  }
`

const ButtonSecondary = styled(Button)`
  background: #374f60;
  :hover {
    background: #4b6679;
  }
`

const Hero = () => {
  return (
    <StyledHero>
      <AbsoluteContainer wMax="xl">
        <GridContainer>
          <TextContainer>
            <Box py={0} pr={[0, 3, 3, 3, 2, 1]} pxMax="md">
              <h1>
                Das schönste Dach für <NoWrap>Ihre Veranstaltung</NoWrap>
              </h1>
              <p>
                Egal ob Hochzeit, Festival, Konzert oder Firmenevent –
                Stretchzelte passen sich ganz einfach an Ihren individuellen
                Anforderungen an.
              </p>
              <p>
                Die Zelte sind robust, regen- und wetterfest und bieten so
                Schutz für Ihre Besucher, Band, DJ oder Tischgesellschaft.
              </p>
              <p>
                Auch optisch sind Stretchzelte ein Highlight für Ihr Event. Sie
                lassen sich nachts wunderbar beleuchten und auf unzählige Art
                und Weisen aufbauen.
              </p>
              <ButtonWrapper>
                <ButtonSecondary
                  onClick={() => {
                    scrollTo('#calculator')
                    typeof window !== 'undefined' &&
                      typeof gtag !== 'undefined' &&
                      window.gtag('event', 'click_hero_calculate', {})
                  }}
                >
                  Miete berechnen
                </ButtonSecondary>
                <Link to="/kontakt">
                  <Button
                    onClick={() => {
                      typeof window !== 'undefined' &&
                        typeof gtag !== 'undefined' &&
                        window.gtag('event', 'click_hero_contact', {})
                    }}
                  >
                    Mietanfrage stellen
                  </Button>
                </Link>
              </ButtonWrapper>
            </Box>
          </TextContainer>
        </GridContainer>
      </AbsoluteContainer>
      <GridContainer>
        <Img />
      </GridContainer>
    </StyledHero>
  )
}

export default Hero
